import * as PIXI from 'pixi.js';

import addColorizeToPixiTextStyle from '../../utils/addColorizeToPixiTextStyle';
import setupPixiTextStyle from '../../utils/setupPixiTextStyle';
import _ from 'lodash';
import type {
  GarmentConfigDefault,
  PlayerNumberConfig,
} from '../../store/types/designTypes';
import type {
  CanvasType,
  FeatureParams,
  GarmentParameters,
} from '../../types/canvasTypes';
import type {
  PlayerNumberParams,
  InscriptionParams,
  DesignFeatureName,
  FeatureState,
} from '../../types/canvasTypes';
import type { TextConfig } from '../../store/types/designTypes';
import { IBaseColor } from '../../store/types/types';
import { textByCircle } from '../../utils/textByCircle';

const typeQfMap = {
  mobile: 2,
  desktop: 2,
  preview: 0.5,
};

export default class CanvasManager {
  private readonly app = new PIXI.Application({
    sharedTicker: false,
    autoStart: true,
  });
  private readonly layer = new PIXI.Container();
  private readonly numberTexts = new Map<'front' | 'back', PIXI.Text>();
  private logos = [];
  public config: GarmentConfigDefault | undefined;
  private lastConfig: GarmentConfigDefault | undefined;
  private lastTextParams: InscriptionParams | undefined;
  private lastNumberState: PlayerNumberParams | undefined;
  private lastColors: IBaseColor[];
  private lastParameters: GarmentParameters | undefined;
  private lastTextSizeReduce: number[] = [];
  private designFeatureStates: Record<DesignFeatureName, FeatureState> = {
    aoMap: {},
    snitchesMask: {},
  };
  public needsRedraw = false;
  private redrawCallback: () => void = () => undefined;
  private assets: Record<string, PIXI.Sprite> = {};
  private type: CanvasType;
  private inscriptions: Record<string, PIXI.Text | PIXI.Container> = {};
  private get qf() {
    return typeQfMap[this.type];
  }

  public redraw() {
    this.app.renderer.render(this.app.stage);
    this.needsRedraw = false;
  }

  public initialize(width: number, height: number, type: CanvasType): void {
    this.layer.sortableChildren = true;
    this.type = type;
    width *= this.qf;
    height *= this.qf;
    this.app.renderer.view.style.width = width + 'px';
    this.app.renderer.view.style.height = height + 'px';
    this.app.renderer.resize(width, height);
    this.app.stage.addChild(this.layer);
  }

  public updateAssets(assets: Record<string, PIXI.Sprite>) {
    this.assets = assets;
  }

  public setConfig(
    config: GarmentConfigDefault,
    assets: Record<string, PIXI.Sprite>,
  ) {
    this.config = config;
    if (
      this.config.texts &&
      this.lastTextSizeReduce.length !== this.config.texts.length
    ) {
      this.lastTextSizeReduce = [];
      for (let i = 0; i < this.config.texts.length; i++) {
        this.lastTextSizeReduce.push(this.config.texts[i].fontSize);
      }
    }
    this.assets = assets;
    this.config.layers.forEach((layer, idx) => {
      this.addFeature({
        url: layer.maskPath,
        type: `layer_${idx}`,
      });
    });
    const layerKeys = Object.keys(this.designFeatureStates).filter((key) =>
      key.includes('layer'),
    );
    const usedLayerIndices = this.config.layers.map((_, idx) => `layer_${idx}`);
    const unusedLayerKeys = layerKeys.filter(
      (key) => !usedLayerIndices.includes(key),
    );
    this.layer.children
      .filter((c) => unusedLayerKeys.includes(c.name ?? null))
      .forEach((c) => (c.visible = false));
    this.layer.children
      .filter((c) => usedLayerIndices.includes(c.name ?? null))
      .forEach((c) => (c.visible = true));
    this.addFeature({
      url: this.config.aoMapPath,
      blendMode: PIXI.BLEND_MODES.MULTIPLY,
      type: 'aoMap',
    });
    this.addFeature({
      url: this.config.snitchesMaskPath,
      type: 'snitchesMask',
    });
    // this.needsRedraw = true;
    // this.redrawCallback();
  }

  public getView(): HTMLCanvasElement {
    return this.app.view;
  }

  public setParameters(parameters: GarmentParameters): void {
    // if (
    //   _.isEqual(this.lastConfig.playerNumbers, this.config.playerNumbers) &&
    //   _.isEqual(parameters, this.lastParameters)
    // ) {
    //   return;
    // }
    // this.lastParameters = parameters;
    const { texts, colors, number } = parameters;
    this.app.renderer.backgroundColor = PIXI.utils.string2hex(
      parameters.colors.Main.hex,
    );
    this.setInscriptions({
      texts: texts,
      fillColor: colors.Text.hex,
      outlineColor: colors['Text outline'].hex,
    });
    this.updateNumber({
      number: number,
      fillColor: colors.Number.hex,
      outlineColor: colors['Number outline'].hex,
    });
    this.updateLogos();
    this.updatePatterns(colors['Side panel']);
    this.needsRedraw = true;
  }

  private updatePatterns(colors: IBaseColor[]) {
    // if (_.isEqual(this.lastColors, colors)) return;
    this.lastColors = colors;
    this.layer.children
      .filter((c) => c.name?.includes('layer'))
      .forEach((l: PIXI.Sprite) => {
        const idx = l.name.split('_')[1];
        l.tint = PIXI.utils.string2hex(
          (colors[idx] ? colors[idx] : colors[0])?.hex,
        );
      });
  }

  private addFeature(params: FeatureParams): FeatureState {
    if (this.designFeatureStates[params.type]?.url === params.url) return;
    const oldSprite = this.layer.children.find((c) => c.name === params.type);
    if (oldSprite) this.layer.removeChild(oldSprite);
    const sprite = this.assets[params.url];
    sprite.width = this.app.renderer.view.width;
    sprite.height = this.app.renderer.view.height;
    if (params.blendMode) sprite.blendMode = params.blendMode;
    sprite.name = params.type;
    sprite.zIndex = 0;
    this.layer.addChild(sprite);
    if (!this.designFeatureStates[params.type])
      this.designFeatureStates[params.type] = {};
    this.designFeatureStates[params.type].url = params.url;
  }

  private updateNumber(state: PlayerNumberParams): void {
    if (!this.config.playerNumbers || this.config.playerNumbers.length === 0) {
      this.numberTexts.forEach((text) => this.layer.removeChild(text));
      return;
    }
    // if (
    //   _.isEqual(this.lastConfig.playerNumbers, this.config.playerNumbers) &&
    //   _.isEqual(this.lastNumberState, state)
    // ) {
    //   return;
    // }
    this.lastNumberState = state;
    const notUsedTexts = Array.from(this.numberTexts.keys()).filter(
      (k) => !this.config.playerNumbers.map((c) => c.position).includes(k),
    );
    notUsedTexts.forEach((text) => {
      this.layer.removeChild(this.numberTexts.get(text));
    });

    this.config.playerNumbers.forEach((numberConfig) => {
      this.setPlayerNumber(numberConfig, state);
    });
    this.needsRedraw = true;
  }

  private setPlayerNumber(
    numberConfig: PlayerNumberConfig,
    params: PlayerNumberParams,
  ) {
    const { number } = params;
    const { gradient, position } = numberConfig;
    const existingText = this.numberTexts.get(position);
    const addition = 0;
    if (existingText) {
      setupPixiTextStyle(
        existingText.style,
        this.type,
        numberConfig,
        'playerNumber',
      );
      addColorizeToPixiTextStyle(existingText.style, params, gradient);
      existingText.text = number;
      existingText.updateText(false);
      existingText.x = addition + numberConfig.x * this.qf;
      existingText.y = numberConfig.y * this.qf;
    } else {
      const textStyle: Partial<PIXI.ITextStyle> = {};
      setupPixiTextStyle(textStyle, this.type, numberConfig, 'playerNumber');
      addColorizeToPixiTextStyle(textStyle, params, gradient);
      const pixiText = new PIXI.Text(number, textStyle);
      pixiText.anchor.set(0.5, 0.5);
      pixiText.x = addition + numberConfig.x * this.qf;
      pixiText.y = numberConfig.y * this.qf;
      // pixiText.calculateBounds();
      this.numberTexts.set(position, pixiText);
      pixiText.zIndex = 30;
      this.layer.addChild(pixiText);
    }
  }

  public getTextFontSizes(textId: number) {
    const { texts: inscriptionConfigs } = this.config;
    const reducedFontSize =
      this.lastTextSizeReduce.length > textId
        ? this.lastTextSizeReduce[textId]
        : undefined;
    return { fontSize: inscriptionConfigs[textId].fontSize, reducedFontSize };
  }

  public setTextReducedFontSize(textId: number, reducedFontSize: number) {
    this.lastTextSizeReduce[textId] = reducedFontSize;
  }

  public getLastPatternId() {
    if (!this.lastParameters) return undefined;
    return this.lastParameters.selectedPatternId;
  }

  public lastText(textId) {
    if (
      this.lastTextParams === undefined ||
      this.lastTextParams.texts.length <= textId
    ) {
      return undefined;
    }
    return this.lastTextParams.texts[textId];
  }

  private setInscriptions(params: InscriptionParams) {
    const { texts: inscriptionConfigs } = this.config;
    const addition = 15 * this.qf;
    if (!inscriptionConfigs || !inscriptionConfigs.length) return;
    if (
      _.isEqual(this.lastConfig?.texts, inscriptionConfigs) &&
      _.isEqual(params, this.lastTextParams)
    )
      return;

    this.lastConfig = _.cloneDeep(this.config);
    this.lastTextParams = params;

    Object.keys(this.inscriptions).forEach((key) => {
      if (key in inscriptionConfigs) return;
      if (this.inscriptions[key]) this.inscriptions[key].visible = false;
    });

    inscriptionConfigs.forEach((inscriptionConfig, index) => {
      const text = params.texts[index] ?? '';
      if (inscriptionConfig.curve) {
        if (this.inscriptions[index]) {
          this.layer.removeChild(this.inscriptions[index]);
          this.inscriptions[index].destroy({
            children: true,
            texture: true,
            baseTexture: true,
          });
          this.inscriptions[index] = null;
        }
        const style = new PIXI.TextStyle();
        setupPixiTextStyle(style, this.type, inscriptionConfig, 'text');
        addColorizeToPixiTextStyle(style, params, inscriptionConfig.gradient);
        this._renderCurvedTextWithRope(text, inscriptionConfig, style, index);
        this.needsRedraw = true;
        return;
      }
      const existingIncsription = this.inscriptions[index];

      if (existingIncsription && existingIncsription instanceof PIXI.Text) {
        setupPixiTextStyle(
          existingIncsription.style,
          this.type,
          inscriptionConfig,
          'text',
        );
        addColorizeToPixiTextStyle(
          existingIncsription.style,
          params,
          inscriptionConfig.gradient,
        );
        existingIncsription.text = text;

        existingIncsription.style.fontSize =
          +existingIncsription.style.fontSize + 0.5;
        let width = 0;
        do {
          width = PIXI.TextMetrics.measureText(
            text,
            existingIncsription.style as PIXI.TextStyle,
          ).width;
          existingIncsription.style.fontSize -= 0.5;
        } while (width > inscriptionConfig.maxTextWidth * this.qf);

        existingIncsription.updateText(false);
        existingIncsription.x =
          addition + inscriptionConfig.x * this.qf + 252.9252 * this.qf;
        existingIncsription.y =
          inscriptionConfig.y * this.qf + this.layer.height / 2;
      } else {
        if (this.inscriptions[index])
          this.layer.removeChild(this.inscriptions[index]);
        const style: Partial<PIXI.ITextStyle> = {};
        setupPixiTextStyle(style, this.type, inscriptionConfig, 'text');
        addColorizeToPixiTextStyle(style, params, inscriptionConfig.gradient);
        const pixiText = new PIXI.Text(text, style);

        pixiText.style.fontSize = +style.fontSize + 0.5;
        let width = 0;
        do {
          width = PIXI.TextMetrics.measureText(
            text,
            pixiText.style as PIXI.TextStyle,
          ).width;
          pixiText.style.fontSize -= 0.5;
        } while (width > inscriptionConfig.maxTextWidth * this.qf);

        pixiText.updateText(false);
        pixiText.anchor.set(0.5, 0.5);
        pixiText.x =
          addition + inscriptionConfig.x * this.qf + 252.9252 * this.qf;
        pixiText.y = inscriptionConfig.y * this.qf + this.layer.height / 2;
        pixiText.zIndex = 40;
        this.layer.addChild(pixiText);
        this.inscriptions[index] = pixiText;
      }
      this.inscriptions[index].visible = true;
      this.needsRedraw = true;
    });
  }

  private _renderCurvedTextWithRope(
    text: string,
    inscriptionConfig: TextConfig,
    style: PIXI.TextStyle,
    index: number,
  ) {
    if (text.trim() === '') return;
    const textEntityName = `text_${index}`;
    const oldText = this.layer.children.find(
      (c) => c.name === textEntityName,
    ) as PIXI.Container;
    if (oldText) {
      this.layer.removeChild(oldText);
      oldText.destroy({ children: true, texture: true, baseTexture: true });
    }
    const pixiText = textByCircle(
      text,
      style,
      inscriptionConfig.curve.semiMajorAxis * this.qf,
      inscriptionConfig.type,
      this.type,
    );
    pixiText.x = inscriptionConfig.x * this.qf + 110 * this.qf; // fix this shit
    pixiText.y = inscriptionConfig.y * this.qf + 135 * this.qf;
    pixiText.zIndex = 40;
    this.layer.addChild(pixiText);
    this.inscriptions[index] = pixiText;
    pixiText.name = textEntityName;
    this.needsRedraw = true;
  }

  private updateLogos() {
    if (!this.config.logos || !this.config.logos.length) return;
    this.layer.children
      .filter((c) => c.name?.includes('logo'))
      .forEach((c) => c.parent.removeChild(c));
    this.logos.forEach((logo, idx) => {
      this.layer.removeChild(logo);
      // logo.destroy({ texture: true, baseTexture: true });
      this.logos[idx] = null;
      delete this.logos[idx];
    });
    // this.needsRedraw = true;
    // this.redrawCallback();
    this.config.logos.forEach((logoConfig, idx) => {
      const sprite = this.assets[`logo_${idx}`];
      if (sprite === undefined) {
        return;
      }
      // const image = new Image();
      // const onCompleted = () => {
      const factorX = sprite.texture.width / (logoConfig.maxSizes[1] * this.qf);
      const factorY =
        sprite.texture.height / (logoConfig.maxSizes[0] * this.qf);
      const factor = Math.max(factorX, factorY);
      // sprite.texture = PIXI.Texture.from(logoSource);
      sprite.width = sprite.texture.width / factor;
      sprite.height = sprite.texture.height / factor;
      sprite.anchor.set(0.5, 0.5);
      sprite.position.x = logoConfig.x * this.qf;
      sprite.position.y = logoConfig.y * this.qf;
      sprite.zIndex = 20;
      sprite.name = `logo_${idx}`;
      this.layer.addChild(sprite);
      this.logos[idx] = sprite;
      // };
      // image.onload = onCompleted;
      // image.src = logoSource;
    });
    // this.needsRedraw = true;
    // this.redrawCallback();
  }
  onRedraw(cb: () => void) {
    this.redrawCallback = cb;
  }
}
